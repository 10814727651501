<template>
    <component
        :is="props.tag"
        class="btn"
        :type="props.type"
        :class="{
            'btn--primary': props.color === 'primary',
            'btn--danger': props.color === 'danger',
            'btn--success': props.color === 'success',
            'btn--small': props.size === 'small',
            'btn--tiny': props.size === 'tiny',
            'btn--loading': props.loading,
            'btn--disabled': props.disabled,
        }"
        :disabled="props.loading || props.disabled"
    >
        <font-awesome-icon
            v-if="props.icon && !props.loading"
            :icon="props.icon"
        />

        <loading
            v-if="props.loading"
            color="white"
            class="mr-2"
        />

        <span>
            <slot />
        </span>
    </component>
</template>

<script setup lang="ts">
    import Loading from '~/components/Loading.vue';
    import type { Icon } from '~/types';

    export interface Props {
        tag?: string;
        type?: 'submit' | 'button' | 'reset';
        color?: 'primary' | 'danger' | 'success';
        size?: 'normal' | 'small' | 'tiny';
        icon?: Icon;
        loading?: boolean;
        disabled?: boolean;
    }

    const props = withDefaults(defineProps<Props>(), {
        tag: 'button',
        type: 'button',
        color: 'primary',
        size: 'normal',
        icon: undefined,
        loading: false,
        disabled: false,
    });
</script>

<style lang="postcss">
    .btn {
        @apply inline-flex items-center px-5 py-1.5 border-2 rounded-lg font-semibold whitespace-nowrap;
        @apply transition duration-200 ease-in-out;

        svg {
            @apply block;

            path {
                fill: currentColor;
                transition: fill 200ms ease-in-out;
            }
        }

        svg + span {
            @apply ml-2;
        }

        span + svg {
            @apply mr-2;
        }

        &--primary {
            @apply bg-primary-500 text-white border-primary-500;

            &:not(:disabled):hover {
                @apply bg-primary-700 border-primary-700;
            }
        }

        &--danger {
            @apply bg-red-600 text-white border-red-600;

            &:not(:disabled):hover {
                @apply bg-red-700 border-red-700;
            }
        }

        &--success {
            @apply bg-green-600 text-white border-green-600;

            &:not(:disabled):hover {
                @apply bg-green-700 border-green-700;
            }
        }

        &--disabled {
            @apply text-white bg-gray-300 border-gray-300 dark:bg-gray-700 dark:border-gray-700 cursor-no-drop;
        }

        &--small {
            @apply px-2 py-1 text-xs lg:px-3 lg:py-1 lg:text-sm;
        }

        &--tiny {
            @apply px-2 py-0.5 text-xs;
        }
    }
</style>
